// import axios from "axios";

import { getCompanyId } from "screens/utils/localStorageUtil";

const URL = process.env.REACT_APP_BACKEND_URL;
// const companyId = getCompanyId();

export const getAPIURLs = (companyId) => {
  return {
    bookings: `${URL}/api/v1/bookings`,
    bookingFilters: `${URL}/api/v1/bookings/filters`,
    questionFilters: `${URL}/api/v1/pending_actions/questions/filters`,
    actionFilters: `${URL}/api/v1/pending_actions/actions/filters`,
    users: `${URL}/api/v1/users`,
    actions: `${URL}/api/v1/pending_actions`,
    usersByCompany: `${URL}/api/v1/companies`,
    updateAction: `${URL}/api/v1/pending_actions`,
    login: `${URL}/login`,
    templates: `${URL}/api/v1/templates`,
    contacts: `${URL}/api/v1/contacts`,
    flows: `${URL}/api/v1/flows`,
    flowActions: `${URL}/api/v1/actions`,
    company: `${URL}/api/v1/companies/${companyId}`,
    companyUsers: `${URL}/api/v1/company`,
    placeholders: `${URL}/api/v1/templates/placeholders`,
    pms: `${URL}/api/v1/pms_options`,
    dashboard: `${URL}/api/v1/company/${companyId}/dashboard`,
    refreshSession: `${URL}/refresh`,
    forgotPassword: `${URL}/forgot_password`,
    resetPassword: `${URL}/reset_password`,
    sendBulkMessage: `${URL}/api/v1/bookings/send_bulk_message`,
    bulkUpdate: `${URL}/api/v1/bookings/bulk_update`,
    getSuggestion: `${URL}/api/v1/messages`,
    sendWhatsappMessage: `${URL}/whatsapp/webhook`,
    properties: `${URL}/api/v1/apartments`,
    create_property: `${URL}/api/v1/create_apartment`,
    edit_property: `${URL}/api/v1/edit_apartment`,
    user_delete: `${URL}/api/v1/users`,
    companySettings: `${URL}/api/v1/company_settings`,
    deleteAction: (actionId) => `${URL}/api/v1/pending_actions/${actionId}`,
  };
};

export const api = () => {
  const companyId = getCompanyId();
  return getAPIURLs(companyId);
};
