import { createSlice } from "@reduxjs/toolkit";

export const bulkMessageSlice = createSlice({
  name: "bulkMessage",
  initialState: {
    enableButton: true, // Set to true initially
    bookingIds: [],
    checkBoxes: [],
    tableData: [],
    isStared: false,
    isArchived: false,
    isCheckAll: false,
  },
  reducers: {
    updateSelectedBookings: (state, action) => {
      const { updatedCheckboxes, tableData } = action.payload;
      state.tableData = tableData;
      const bookingIds = tableData
        ?.filter((_, i) => updatedCheckboxes[i])
        ?.map((rowData) => rowData);

      state.bookingIds = bookingIds;
      state.enableButton = bookingIds?.length === 0; // Update enableButton based on bookingIds length
    },
    removeSelectedBookings: (state, action) => {
      state.bookingIds = [];
      state.enableButton = true; // Update enableButton based on bookingIds length
    },
    fillCheckbox: (state, action) => {
      state.checkBoxes = action.payload.tableData.map(() => false);
      state.tableData = [];
    },
    singleCheckbox: (state, action) => {
      state.checkBoxes = action.payload;
    },
    allCheckboxes: (state, action) => {
      state.checkBoxes = action.payload.tableData.map(() => true);
    },
    setIsStared: (state, action) => {
      state.isStared = action.payload;
    },
    setIsArchived: (state, action) => {
      state.isArchived = action.payload;
    },
    setIsCheckAll: (state, action) => {
      state.isCheckAll = action.payload;
    },
    resetSelectedBookings: (state, action) => {
      state.bookingIds = [];
      state.checkBoxes = [];
      state.tableData = [];
      state.isCheckAll = false;
      state.enableButton = true;
    },
  },
});

export const {
  updateSelectedBookings,
  removeSelectedBookings,
  fillCheckbox,
  singleCheckbox,
  allCheckboxes,
  setIsStared,
  setIsArchived,
  setIsCheckAll,
  resetSelectedBookings,
} = bulkMessageSlice.actions;

export const bulkMessageSelector = (state) => state.bulkMessage;

export default bulkMessageSlice.reducer;
